


import React, { useContext } from "react";
import { graphql, useStaticQuery } from "gatsby";


const DataContext = React.createContext();

export const useData = () => {
  return useContext(DataContext);
};



export const DataProvider = ({children}) => {

const data = useStaticQuery(graphql`
    {
      allWpPost {
        edges {
          node {
            title
            excerpt
            slug
            date
            id
            content
            featuredImage {
              node {
                sourceUrl
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      placeholder: BLURRED
                    )
                  }
                }
              }
            }
            categories {
              nodes {
                name
                slug
              }
            }
            tags {
              nodes {
                slug
                name
              }
            }
            author {
              node {
                name
              }
            }
          }
        }
      }
      allWpPage {
        edges {
          node {
            promotion {
              cta
              promotionText
              url
            }
            contact {
              textContent
            }
            title
            content
            featuredImage {
              node {
                sourceUrl
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      placeholder: BLURRED
                    )
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  return (
    <DataContext.Provider value={data}>
        {children}
    </DataContext.Provider>
  );
};
